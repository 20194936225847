@import '~antd/dist/antd.css'; 


body {
    font-family: Helvetica, Arial, san-serif;

}
html, body root,  .record-viewer {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    color: black;
    position: fixed;
  }

body, h1, h2, h3, h4, h5, h6{
    color: black;
}

h1, h2, h3, h4, h5, h6 {
    color: black;
    font-weight: 600;
}
:any-link:hover, a:hover {
    text-decoration: underline;
}
.record-detail-lightbox {
    background-color: white;
}
.record-search .ant-select-auto-complete.ant-select .ant-input, 
.record-search .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    font-size: 16px;
}
.ant-tree-node-content-wrapper-open, .ant-tree-node-content-wrapper-close {
    font-weight: bold;
}

.ant-tree-child-tree > li:first-child {
    padding-top: inherit; 
}

.ant-tree li {
    padding-top: 1px;
    padding-bottom: 1px;
}
.ant-tree li .ant-tree-node-content-wrapper {
    color: black;
}
.ant-select-auto-complete {
    width: 100%;
}
.ant-input, .ant-select-dropdown-menu-item {
    color: black;
}

.record-viewer header {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;

}

.record-search {
    flex-grow: 1;
    padding-left: 2em;
    padding-top: 8px;
}

.record-select {
    flex: 1 0;
    min-width: 140px;
}

.record-detail {
    padding-left: 35px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 32px;

    overflow: auto;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */

}

.record-detail .definition .structure-name {
    font-style: oblique;
    font-weight: 700;
}
.record-detail th {
   width: 20em; 
    padding-right: 2em;
    vertical-align: top;
}

.record-detail .children {
    padding-left: 0px;
}
.record-detail .children li {
    list-style: none;
}

.record-detail .children li .button{
    padding-left: 0px;
}
.record-detail th, td {
    padding-bottom: 0px;
}

.record-detail-indent {
    padding-left: 0px;
}

.record-detail-header {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    padding-bottom: 4px;
    padding-top: 8px;
    color: #555;
}
.record-detail h2 {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 6px;
    margin-top: 0px;
}

.record-detail .link-button {
    height: inherit;
    padding: 0;
    margin: 0;
    border: none;
}

.record-splash-text h1 {
    font-size: 16px;
}
.record-splash-text {
    margin: 20px;
}
.record-detail h2:first-letter {
    text-transform: capitalize;
} 

.record-detail .disambiguation {
    margin-top: -10px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
}

.record-detail .detail-subheader-key {
    padding-right: 1em;
}

.record-detail .separator-line {
    margin-left: -20px;
    border-top-color: rgba(0, 0, 0, 0.2);
    margin-top: 15px;
    margin-bottom: 8px;
}
.initial-cap {
    display: inline-block;
}
.initial-cap:first-letter {
    text-transform: capitalize;
}

.record-tree {
    padding-left: 10px;
    padding-top: 4px;
    height: 100%;
    width: 100%;
}

.record-tree-item {
    display: inline-block;
}
.record-tree-item:first-letter {
    text-transform: capitalize;
} 

.ant-collapse-header > .ant-collapse-arrow {
    margin-left: 5px;
  }
  
.ant-collapse-content {
    color: black;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: -42px;
    font-weight: bold;
    color: black;
  }

 .ant-collapse-borderless > 
    .ant-collapse-item > 
    .ant-collapse-content > 
    .ant-collapse-content-box {
      padding-bottom: 0px;
      padding-left: 0px;
      padding-top: 0px;
  }
  
  .ant-collapse-borderless > 
    .ant-collapse-item > 
    .ant-collapse-content > 
    .ant-collapse-content-box .children {
        margin-bottom: 0px;
}


  .ant-collapse-borderless {
    background-color: white;
    border: 0;
}

.ant-collapse-content {
    padding-left: 0px;
  }
  
.ant-collapse > .ant-collapse-item {
    border: 0px solid black;
  }

/* 
.record-tree-item.level-H0 {
    font-size: 14pt;
    font-weight: bolder;
}

.record-tree-item.level-H1 {
    font-size: 14pt;
    font-weight: bolder;

}

.record-tree-item.level-H2 {
    font-size: 14pt;
    font-weight: bold;
}

.record-tree-item.level-H3 {
    font-size: 12pt;
    font-weight: bold;
}

.record-tree-item.level-H4 {
    font-size: 10pt;
    font-weight: bold;
}

.record-tree-item.level-H5 {
    font-size: 10pt;
    font-style: oblique;
    font-weight: bold;
}

.record-tree-item.level-H6 {
    font-size: 10pt;
    font-style: oblique;
    font-weight: bold;
    color: #444;
}

.record-tree-item.level-H7 {
    font-size: 10pt;
    font-style: oblique;
    font-weight: bold;
    color: #444;
}
*/

.record-tree-item.level-H0,
.record-tree-item.level-H1,
.record-tree-item.level-H2,
.record-tree-item.level-H3,
.record-tree-item.level-H4,
.record-tree-item.level-H5,
.record-tree-item.level-H6,
.record-tree-item.level-H7 {
    font-style: normal;
    font-weight: bold;
}

.record-tree-item.level-T1, 
.record-tree-item.level-T2, 
.record-tree-item.level-T3, 
.record-tree-item.level-T4 {
    font-style: normal;
    font-weight: normal;
}

.record-detail-breadcrumbs {
    margin-top: 0px;
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 16px;
  }

  .record-detail-breadcrumbs .button {
        padding-left: 0em;
        padding-right: 0em;
        height: 1.5em;
  }

  .record-detail-breadcrumbs .separator {
    padding-left: 0.0em;
    padding-right: 0.25em;
  }

.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

 .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    /* cursor: row-resize; */
    width: 100%;
}

/* .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
} */

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.record-viewer {
    font-family: Helvetica, Arial, san-serif;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }


.record-tree {
    padding: 10px;
    flex: 1 1 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  }

  .record-buttonbar {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    flex: 1;
    max-width: 300px;

  }
  .record-buttonbar .buttons {
    flex-direction: row;
    display: flex;
    /* flex: 0.5; */
  }


  .record-main {
    display: flex;
    padding-top: 3px;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    height: 100%;
  }
  
.record-detail th {
    text-align: left;
}

.record-title {
    cursor: pointer;
    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
}

.ant-modal-title {
    font-size: 18px;
    font-weight: bold;
}



@media (max-device-width: 800px) {
    .record-detail-header {
        font-size: 20px;
        padding-top: 4px;
        padding-bottom: 6px; 
    }

    .record-tree {
        overflow: unset;
    }

    .record-detail table {
        font-size: 16px;
    }

    .ant-btn-sm {
        font-size: 16px;
    }

    .record-tree-item {
        font-size: 16px;
    }
    .ant-collapse-header {
        font-size: 16px;
    }

    .record-menubar {
      padding-right: 10px;
      flex: 1 1 600px;
      flex-flow: row wrap;
      padding-right: 10px;
    }
  
    .record-search-spacer {
      display: none;
    }
    .record-search {
      flex: 1 100%;
      order: 5;
      padding-left: 0px;
      padding-top: 0px;
    }
    .record-viewer header {
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 10px;
      padding-right: 10px;
    }
    .record-title {
      flex: 0.5 0;
      padding-top: 0px;
      padding-left: 0px;
      align-self: flex-start;
    }
    .record-title h1 {
      font-size: 18px;
      padding-bottom: 0px;
      margin-top: 10px;
      white-space: nowrap;
    }
    .record-search .ant-select-auto-complete.ant-select .ant-input, 
    .record-search .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
        font-size: 18px;
        height: 36px;
    }

    .record-controls {
      flex: 1.5;
      padding-top: 0px;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .record-detail {
      padding: 0px 10px 10px 20px;
      flex: 0;
      border-bottom: 1px solid #dddddd;
      order: -1;
      overflow: unset;
    }
    .record-detail h2 {
      font-size: 20px;
      padding-bottom: 5px;
      margin-bottom: 1px;
    }
    .record-detail th {
        width: 12em; 

     }
    .record-viewer {
        -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 800px) and (min-device-width: 800px){
    h1 {
        font-size: 20px;
        padding-bottom: 0px;
        margin-top: 10px;
        white-space: nowrap;

      }
      .record-detail table {
      margin-bottom: 20px;
      }

      .record-search {
        padding-left: 10px;
      }
  }